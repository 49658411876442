import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

//components
import ModalButton from './modalButton';
import { device } from 'components/device';

//imgs
import CloseIcon from 'images/icons/close.svg';

//utils
import { openLinkNewTab, copyCurrentUrl } from 'utils/urlUtils';

const customStyles = {
  content: {
    width: '24.75rem',
    height: '21.118rem',
    border: '1px solid #c6c8cc',
    boxShadow: '0 5px 10px #0000000d',
    borderRadius: '8px',
    bottom: '70px',
    top: '31%',
    left: '40%',
    background: '#fff',
    padding: '1.875rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
};

const MobileContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 10;
  top: 17%;
  overflow: hidden;
  @media ${device.laptop} {
    padding-top: 30px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;

const Title = styled.h2`
  color: #000000;
  font-weight: normal;
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  margin: 0;
`;

const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid #c6c8cc;

  img {
    cursor: pointer;
  }
`;

Modal.setAppElement('body');

const ModalShare = ({ links, onClose, showModal }) => {
  const [currentUrl, setCurrentUrl] = useState('');
  const [desktop, setDesktop] = useState(true);

  useEffect(() => {
    setDesktop(window.innerWidth > 1024);
    setCurrentUrl(copyCurrentUrl(true));
  }, []);

  const renderModal = () => {
    return (
      <div data-testid="test-dom">
        <Modal
          isOpen={showModal}
          onRequestClose={() => onClose(false)}
          style={customStyles}
          contentLabel="Modal Compartilhar"
          shouldCloseOnOverlayClick
        >
          <Container>
            <BoxTitle>
              <Title>Compartilhar</Title>
              <img
                src={CloseIcon}
                alt="Fechar"
                onClick={() => onClose(false)}
              />
            </BoxTitle>
            <ModalButton links={links} onClick={copyLink} />
          </Container>
        </Modal>
      </div>
    );
  };

  const renderResponsiveModal = () => {
    return (
      <MobileContainer>
        <Container>
          <ModalButton links={links} onClick={copyLink} responsive />
        </Container>
      </MobileContainer>
    );
  };

  const copyLink = type => {
    if (type && currentUrl) {
      openLinkNewTab(currentUrl, type);
    }
  };
  return desktop ? renderModal() : renderResponsiveModal();
};

export default ModalShare;

/*
Função para converter String em Template Literals(Template String)
Primeiro parâmetro (template) recebe uma string com a formatação 
de template literals - ex.: 'meu nome é ${name}'
Segundo parâmetro (params) recebe um objeto com as chaves e valores para 
substituição do template - ex.: {name: 'Fulano'}

O retorno da função gera uma string com os template literals já executadas.
*/

export const stringToTemplateLiterals = function (template, params) {
  const objectParams = Object.keys(params);
  const objectValues = Object.values(params);
  // eslint-disable-next-line no-new-func
  return new Function(...objectParams, `return \`${template}\`;`)(
    ...objectValues
  );
};

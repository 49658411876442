// Libs
import React, { Fragment, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';

// Components
import ListPosts from 'components/blog/listPosts';
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import BreadCrumb from 'components/breadCrumb';
import ModalShare from 'components/modalShare';
import { stringToTemplateLiterals } from 'utils/stringToTemplateLiteral';

// Image
import MandalaHSL from 'images/icons/mandala_hsl.svg';
import ArrowLeft from 'images/icons/arrow-left.svg';
import CloseIcon from 'images/icons/closeblack.svg';

// Assets
import DotsHorizontal from 'images/icons/dots-horizontal.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1170px;
  margin: 1.875rem auto 4.375rem;

  @media ${device.laptop} {
    margin: 0 auto 1.875rem;
  }
`;

export const TagCloudLink = styled(Link)`
  ${props =>
    props.lastItem
      ? css`
          &::after {
            content: '';
          }
        `
      : css`
          &::after {
            content: ', ';
          }
        `}
`;

export const Part = styled.div`
  width: 100%;
  grid-column: ${props => props.gridColumn};
  margin-top: 4.375rem;

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIUnits ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

const HeaderModal = styled.header`
  display: none;
  visibility: hidden;
  z-index: 100;
  height: 100%;
  max-height: 7.5rem;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  background: #fff;

  padding: 0 1.5rem 1.875rem;

  @media ${device.laptop} {
    display: flex;
    visibility: visible;
  }

  @media ${device.mobile} {
    max-height: 6rem;
  }

  a {
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div {
    width: 100%;
    display: flex;

    &.header__left {
      justify-content: flex-start;
    }

    &.header__right {
      justify-content: flex-end;
    }
  }

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    font-weight: normal;
    color: #231f20;
  }
`;

const HeaderModalBackButton = styled.button`
  margin: 0;
  padding: 0;
  line-height: 0;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4rem;

  @media ${device.laptop} {
    flex-direction: column;
    gap: 1.875rem;
    margin: 7.5rem 1.25rem 0;
  }

  @media ${device.mobile} {
    margin: 5rem 1.25rem 0;
  }
`;

const DoctorContactInformation = styled.section`
  width: 100%;
  max-width: 29.375rem;
  height: fit-content;
  position: relative;

  padding-bottom: 1.875rem;
  border: 1px solid #c6c8cc;
  border-radius: 8px;

  @media ${device.laptop} {
    padding-bottom: 1.25rem;
    max-width: unset;
  }

  > div {
    padding: 0 1.875rem;

    @media ${device.mobile} {
      padding: 0 1.25rem;
    }

    & + div {
      border-top: 1px solid #c6c8cc;
      margin-top: 1.875rem;
      padding-top: 1.875rem;

      @media ${device.mobile} {
        margin-top: 1.25rem;
        padding-top: 1.25rem;
      }
    }
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 600;
    color: #231f20;
    margin: 1.25rem 0 0;

    @media ${device.mobile} {
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
  }

  h4 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.625rem;
    color: #231f20;
    margin: 0;

    @media ${device.mobile} {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }

  p {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.625rem;
    color: #8f9194;

    @media ${device.mobile} {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }

  span {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.625rem;
    color: #45a7df;

    @media ${device.mobile} {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }

  a {
    font-size: 1rem;
    color: #45a7df;

    @media ${device.mobile} {
      font-size: 0.875rem;
    }
  }

  p + h4,
  span + h4,
  a + h4,
  button + h4 {
    margin-top: 1.875rem;

    @media ${device.mobile} {
      margin-top: 1.25rem;
    }
  }
`;

const DoctorCuriositiesInformation = styled(ReactMarkdown)`
  width: 100%;
  max-width: 35.625rem;

  @media ${device.laptop} {
    max-width: unset;
  }

  h3 {
    margin-bottom: 1.875rem;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.875rem;
    color: #231f20;

    @media ${device.mobile} {
      margin-bottom: 1.25rem;
      font-size: 1.125rem;
      line-height: 1.375rem;
    }
  }

  h4 {
    margin: 1.25rem 0;
    font-size: 1rem;
    line-height: 1.625rem;
    font-weight: 600;
    color: #231f20;

    @media ${device.mobile} {
      margin: 1rem 0;
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }

  ul {
    margin: 0;
    list-style-type: none;
    li {
      display: flex;
      align-items: flex-start;
      img {
        margin: 0 0.75rem 0 0;
      }
    }
  }

  p,
  li {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.625rem;
    color: #231f20;

    @media ${device.mobile} {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }

  p + h3,
  ul + h3 {
    margin-top: 4.375rem;

    @media ${device.mobile} {
      margin-top: 1.875rem;
    }
  }

  ul {
    margin-bottom: 0;

    li {
      margin: 0;

      & + li {
        margin-top: 1.25rem;
      }

      &::marker {
        color: #45a7df;
      }
    }
  }
`;

const DoctorAvatarContainer = styled.figure`
  position: relative;
  width: 7.5rem;
  height: 7.5rem;
  margin-top: -3.75rem;

  @media ${device.mobile} {
    width: 6.25rem;
    height: 6.25rem;
    margin-top: -3.125rem;
  }
`;

const DoctorAvatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

const DoctorAvatarMandala = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: fill;
  object-position: center;

  @media ${device.mobile} {
    width: 1.875rem;
    height: 1.875rem;
  }
`;

// Component ShareButton
const ShareButton = styled.button`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  line-height: 0;
  background: transparent;
  border: 0;

  @media ${device.laptop} {
    display: ${props => props.displayOnlyDesktop && 'none'};
    position: unset;
    top: unset;
    right: unset;
  }
`;

const ModalCloseButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const ScheduleButton = styled.button`
  font-size: 0.875rem;
  font-weight: normal;
  color: #ffffff;
  text-align: center;

  background: #45a7df;
  padding: 0.875rem 1.25rem;
  margin-top: 1.25rem;

  border: 0;
  border-radius: 100px;
`;

const Doctor = ({
  data: {
    doctorsApi,
    gcms: {
      site: { doctors },
      fragments,
    },
  },
  pageContext,
}) => {
  const {
    id_api,
    name,
    nameWithAssignment,
    crm,
    employee,
    specialty,
    phonecommercial,
    formattedPhonecommercial,
    photo,
  } = pageContext;
  const findDoctorApi =
    doctorsApi?.doctors?.find(doctor => doctor.id === id_api) ?? [];
  const practiceArea =
    findDoctorApi?.practiceArea !== null ? findDoctorApi?.practiceArea : false;

  const descriptionSEOTemplate = fragments[0]?.markdown ?? '';

  const descriptionWithTemplate = stringToTemplateLiterals(
    descriptionSEOTemplate,
    {
      name: nameWithAssignment,
      crm: crm,
      specialty: specialty,
      center: doctors[0]?.specialties?.map(spec => spec.name)?.join(', ') ?? '',
    }
  );

  const formattedCRM = crm ? crm.replace(/[^A-Z0-9]/gi, '') : '';
  const titleSeo = name && formattedCRM ? `${name} - CRM ${formattedCRM}` : '';
  const defaultTitleSeo = fragments[0]?.singletexts?.[1] || '';
  const descriptionSEO =
    crm && nameWithAssignment && specialty
      ? descriptionWithTemplate
      : fragments[0]?.singletexts?.[0] ?? '';

  const dataSeo = [
    {
      seo: {
        metaTitle: titleSeo,
        metaDescription: descriptionSEO,
      },
    },
  ];

  const [showModal, setShowModal] = useState(false);

  const listItemRenderer = ({ children }) => {
    const image = children.filter(child => child.type === 'img');
    const text = children.filter(child => child.type !== 'img');

    return (
      <li>
        {image}
        <div>{text}</div>
      </li>
    );
  };

  function normalizeComma(value) {
    return value.replace(/,/g, ', ');
  }

  function phoneFormatting(tel) {
    if (tel) {
      tel = tel.replace(/\D/g, '');

      if (tel.length === 10) {
        tel = tel.replace(/(.{0})(\d)/, '$1($2');
        tel = tel.replace(/(.{3})(\d)/, '$1)$2');
        tel = tel.replace(/(.{4})$/, '-$1');

        return tel;
      } else if (tel.length === 11) {
        tel = tel.replace(/(.{0})(\d)/, '$1($2');
        tel = tel.replace(/(.{3})(\d)/, '$1)$2');
        tel = tel.replace(/(.{4})$/, '-$1');
        return tel;
      }

      tel = tel.replace(/\D/g, '');

      if (tel.length === 10) {
        tel = tel.replace(/(.{0})(\d)/, '$1($2');
        tel = tel.replace(/(.{3})(\d)/, '$1)$2');
        tel = tel.replace(/(.{4})$/, '-$1');

        return tel;
      } else if (tel.length === 11) {
        tel = tel.replace(/(.{0})(\d)/, '$1($2');
        tel = tel.replace(/(.{3})(\d)/, '$1)$2');
        tel = tel.replace(/(.{4})$/, '-$1');
        return tel;
      }

      tel = tel.replace(/\D/g, '');
      tel = tel.replace(/^(\d)/, '+$1');
      tel = tel.replace(/(.{3})(\d)/, '$1($2');
      tel = tel.replace(/(.{6})(\d)/, '$1)$2');

      if (tel.length === 12) {
        tel = tel.replace(/(.{1})$/, '-$1');
      } else if (tel.length === 13) {
        tel = tel.replace(/(.{2})$/, '-$1');
      } else if (tel.length === 14) {
        tel = tel.replace(/(.{3})$/, '-$1');
      } else if (tel.length === 15) {
        tel = tel.replace(/(.{4})$/, '-$1');
      } else if (tel.length > 15) {
        tel = tel.replace(/(.{4})$/, '-$1');
      }

      tel = tel.replace('55', '55 ');
      tel = tel.replace('(11)', '(11) ');
      return tel;
    }
  }

  return (
    <Layout>
      <SEO dataSeo={dataSeo} title={defaultTitleSeo} />
      <div style={{ display: showModal ? 'block' : 'none' }}>
        <ModalShare
          onClose={() => {
            setShowModal(!showModal);
          }}
          showModal={showModal}
          links={[
            { msg: 'Enviar por WhatsApp', type: 'wpp', id: 1 },
            { msg: 'Enviar por e-mail', type: 'email', id: 2 },
            { msg: 'Copiar URL', type: 'text', id: 3 },
          ]}
        />
      </div>
      <Container>
        <HeaderModal>
          <div className="header__left">
            {showModal ? (
              <ModalCloseButton
                onClick={() => {
                  setShowModal(!showModal);
                }}
              >
                <img src={CloseIcon} alt="Fechar" />
              </ModalCloseButton>
            ) : (
              <HeaderModalBackButton onClick={() => navigate(-1)}>
                <img src={ArrowLeft} alt="Fechar" />
              </HeaderModalBackButton>
            )}
          </div>
          <span>{showModal ? 'Compartilhar' : 'Perfil'}</span>
          <div className="header__right">
            {/* Modal to share Doctor link */}
            {!showModal && (
              <ShareButton onClick={() => setShowModal(!showModal)}>
                <img src={DotsHorizontal} alt="compartilhar" />
              </ShareButton>
            )}
          </div>
        </HeaderModal>
        <BreadCrumb
          markdown={`1. [Home](/) › [Encontre seu médico](/encontre-seu-medico/) › ${name}`}
        />
        <Content>
          <DoctorContactInformation>
            <div>
              <DoctorAvatarContainer>
                <DoctorAvatar
                  src={
                    photo?.url
                      ? photo.url
                      : `https://corpoclinico.hsl.org.br/ImagemVisualizar.ashx?codigoMedico=${id_api}`
                  }
                  alt={name}
                />
                {employee && (
                  <DoctorAvatarMandala
                    src={MandalaHSL}
                    alt="Mandala Sírio Libanês"
                  />
                )}
              </DoctorAvatarContainer>
              {/* Modal to share Doctor link */}
              <ShareButton
                displayOnlyDesktop
                onClick={() => setShowModal(!showModal)}
              >
                <img src={DotsHorizontal} alt="compartilhar" />
              </ShareButton>

              <h2>{nameWithAssignment}</h2>
              {crm && <p>CRM {crm}</p>}

              {doctors[0]?.schedulingOnline && (
                <ScheduleButton>Agendar uma consulta</ScheduleButton>
              )}
            </div>
            <div>
              {doctors[0]?.positionHSL && (
                <>
                  <h4>Cargo no Sírio-Libanês</h4>
                  <p>{doctors[0]?.positionHSL}</p>
                </>
              )}

              {doctors[0]?.specialties[0]?.name && (
                <>
                  <h4>Integrante do Núcleo ou Centro</h4>
                  {doctors[0]?.specialties.map(
                    ({ name, slug, customUrl, unity }, i, arr) => {
                      return customUrl ? (
                        <TagCloudLink
                          to={`${customUrl}`}
                          key={name}
                          lastItem={i === arr.length - 1}
                        >
                          {name}
                        </TagCloudLink>
                      ) : (
                        <TagCloudLink
                          to={`/especialidades-medicas/${
                            unity.includes('sp') ? 'sao-paulo' : 'brasilia'
                          }/${slug}`}
                          key={name}
                          lastItem={i === arr.length - 1}
                        >
                          {name}
                        </TagCloudLink>
                      );
                    }
                  )}
                </>
              )}

              {specialty && (
                <>
                  <h4>Especialidade médica</h4>
                  <p>{normalizeComma(specialty)}</p>
                </>
              )}

              {practiceArea && (
                <>
                  <h4>Área de Atuação</h4>
                  <p>{normalizeComma(practiceArea)}</p>
                </>
              )}

              {doctors[0]?.units?.length > 0 && (
                <>
                  <h4>Unidades que atendem</h4>
                  <span>
                    {doctors[0]?.units.map((unit, index) => {
                      if (doctors[0]?.units.length === index + 1) {
                        return (
                          <Fragment key={index}>
                            <a href={unit.link}>{unit.name}</a>
                          </Fragment>
                        );
                      }
                      return (
                        <Fragment key={index}>
                          <a href={unit.link}>{unit.name}</a>,{' '}
                        </Fragment>
                      );
                    })}
                  </span>
                </>
              )}

              {phonecommercial && (
                <>
                  <h4>Telefone</h4>
                  <span>
                    <a href={`tel:${phonecommercial}`}>
                      {phoneFormatting(formattedPhonecommercial)}
                    </a>
                  </span>
                </>
              )}

              {doctors[0]?.resumeLattes && (
                <>
                  <h4>Currículo Lattes</h4>
                  <a
                    href={doctors[0]?.resumeLattes}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visualizar currículo
                  </a>
                </>
              )}
            </div>
          </DoctorContactInformation>

          <DoctorCuriositiesInformation
            children={doctors[0]?.doctorInformation || ''}
            renderers={{
              listItem: listItemRenderer,
            }}
          />

          {doctors?.[0]?.posts?.length > 0 && (
            <Part gridColumn={'2 / -2'} padding borderTop>
              <ListPosts
                title="Blog do Sírio-Libanês"
                postsToShow={3}
                posts={doctors?.[0]?.posts}
              />
            </Part>
          )}
        </Content>
      </Container>
    </Layout>
  );
};

export const DoctorPageQuery = graphql`
  query DoctorPageQuery($id_cms: ID) {
    gcms {
      fragments(locales: pt_BR, where: { id: "cksrxfrl48q4g0c29b3kvw1a2" }) {
        singletexts
        markdown
      }
      site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
        doctors(locales: pt_BR, where: { id: $id_cms }) {
          specialties {
            id
            name
            slug
            customUrl
            unity
          }
          assignment
          active
          schedulingOnline
          positionHSL
          resumeLattes
          doctorInformation
          units {
            name
            link
            addressAndInformationJSON
          }
          posts(locales: [en, pt_BR, es]) {
            id
            slugPost
            title
            topic
            author
            doctors(first: 1000, locales: [pt_BR, en]) {
              id
              id_api
              active
              assignment
              name
            }
            assetpicker {
              handle
              height
              width
            }
            categories {
              tag
            }
          }
        }
      }
    }
    doctorsApi {
      doctors {
        id
        practiceArea
      }
    }
  }
`;

export default Doctor;

export function shareLink(url, type) {
    let baseUrl;
    switch (type) {
      case 'wpp':
        baseUrl = `https://wa.me/?text=${url}`;
        return baseUrl;
      case 'email':
        baseUrl = `mailto:?body=${url}`;
        return baseUrl;
      case 'text':
        return baseUrl;
    }
  }
  
  export function copyCurrentUrl(getUrl) {
    if (getUrl && window) {
      const url = window.location.href;
      return url;
    } else {
      const el = document.createElement('input');
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      return el.value;
    }
  }
  
  export function openLinkNewTab(url, type) {
    if (type !== 'text') {
      window.open(shareLink(url, type), '_blank');
    } else {
      copyCurrentUrl();
    }
  }
  
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

// components
import { device } from '../../device';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1.75rem;
  cursor: pointer;
  z-index: 999;
  a {
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    padding: 20px 20px;
    margin-bottom: 1rem;
    height: 3.563rem;
    width: 21rem;
    color: #45a7df;
    background: #fff;
    border-radius: 1.875rem;
    border: 1px solid #c6c8cc;

    p.animated {
      ${props =>
        props.isAnimated &&
        css`
          animation: 'ShadeIn' 2s;
        `}

      @keyframes ShadeIn {
        0% {
          color: #45a7df;
          transform: rotateX(0deg);
        }
        10% {
          color: #8f9194;
          transform: rotateX(90deg);
        }
        50% {
          color: #8f9194;
          transform: rotateX(0deg);
        }
        85% {
          color: #8f9194;
          transform: rotateX(90deg);
        }
        100% {
          color: #45a7df;
          transform: rotateX(0deg);
        }
      }
    }

    @media ${device.laptop} {
      width: 100%;
      margin-top: 0;
    }
    @media ${device.mobile} {
      font-size: 14px;
    }
    @media ${device.mobile320} {
      font-size: 12px;
    }
  }
  @media ${device.laptop} {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
`;

function ModalButton({ links, onClick, responsive }) {
  const [buttonAnimation, setButtonAnimation] = useState(false);
  const [buttonText, setButtonText] = useState('');

  function handleClick(link) {
    onClick(link.type ? link.type : '');

    if (link.type === 'text') {
      handleAnimation(link);
      setButtonText(link.msg);
    }
  }

  const handleAnimation = link => {
    setButtonAnimation(true);

    setTimeout(() => {
      setButtonText(link.msg);
    }, 1500);

    setTimeout(() => {
      setButtonText('URL copiada');
    }, 200);

    setTimeout(() => {
      setButtonText(link.msg);
      setButtonAnimation(false);
    }, 2000);
  };

  return (
    <Container isAnimated={buttonAnimation}>
      {links.map(link => {
        return (
          <a key={link.id} onClick={() => handleClick(link)}>
            <p className={link.type === 'text' ? 'animated' : ''}>
              {link.type === 'text' && buttonText !== ''
                ? buttonText
                : link.msg}
            </p>
          </a>
        );
      })}
    </Container>
  );
}

export default ModalButton;
